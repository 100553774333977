<template>
  <div class="d-flex reviewLoading st_background_white p-3 p-md-0">
    <div class="reviewLoading__image rounded-circle" />
    <div class="flex-fill ml-2">
      <div class="d-flex justify-content-between mt-3">
        <div class="reviewLoading__name w-100" />
        <div class="reviewLoading__level w-100" />
      </div>
      <div class="reviewLoading__content mt-4 w-100" />
      <div class="d-flex justify-content-end mt-3">
        <div class="reviewLoading__date w-100" />
      </div>
      <div class="reviewLoading__reply w-100 mt-3" />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
.reviewLoading {
  &__image {
    width: 40px;
    height: 40px;

    @include animation;
  }

  &__name {
    max-width: 50px;
    height: 8px;
    border-radius: 4px;

    @include animation;
  }

  &__level {
    max-width: 40px;
    height: 8px;
    border-radius: 4px;

    @include animation;
  }

  &__content {
    max-width: 500px;
    height: 8px;
    border-radius: 4px;

    @include animation;
  }

  &__date {
    max-width: 80px;
    height: 8px;

    @include animation;
  }

  &__reply {
    max-width: 600px;
    height: 88px;

    @include animation;
  }
}
</style>
