var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":""},on:{"appear":_vm.handleEnter}},[_c('div',{staticClass:"st_background_white comment"},[(_vm.isAdmin)?_c('div',{staticClass:"d-md-none frame-dot comment__tool text-right mt-n2"},[_c('b-dropdown',{staticClass:"bg-none border-none mt-n2",attrs:{"id":"userdown","right":"","noCaret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"st_disable-none-big fas fa-cog cursor-pointer"})]),_vm._v(" "),_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.$root.$emit('bv::show::modal', _vm.comment.identity)}}},[_vm._v("\n          移除評論\n        ")])],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center comment__name"},[_c('nuxt-link',{attrs:{"to":{name:'u-uid', params:{uid: _vm.comment.account.serial}}}},[_c('ScanTraderAvatar',{staticClass:"mr-2",attrs:{"width":"40","height":"40","src":_vm.comment.account.avatar,"lazy":""}})],1),_vm._v(" "),_c('nuxt-link',{staticClass:"st_title-none-base text-ellipsis",attrs:{"to":{name:'u-uid', params:{uid: _vm.comment.account.serial}}}},[_vm._v("\n          "+_vm._s(_vm.comment.account.name.nick)+_vm._s((_vm.comment.account.belongOrg && _vm._.get(_vm.comment.account, 'name.real', '')) ? ("．" + (_vm.comment.account.name.real || '')) : '')+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"st_none-none-small d-flex align-items-center comment__level text-nowrap",class:{
            'st_color_sub': ['good', 'mixed'].includes(_vm.level.id),
            'st_color_gray_dark': _vm.level.id === 'bad',
            'mr-md-1': _vm.isAdmin
          }},[_c('svg-icon',{staticClass:"mr-1",attrs:{"width":"20","height":"20","name":_vm.level.icon}}),_vm._v("\n          "+_vm._s(_vm.level.text)+"\n        ")],1),_vm._v(" "),(_vm.isAdmin)?_c('div',{staticClass:"d-none d-md-block frame-dot ml-2 comment__tool"},[_c('b-dropdown',{staticClass:"bg-none border-none",attrs:{"id":"userdown","right":"","noCaret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"st_disable-none-big fas fa-cog cursor-pointer"})]),_vm._v(" "),_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.$root.$emit('bv::show::modal', _vm.comment.identity)}}},[_vm._v("\n              移除評論\n            ")])],2)],1):_vm._e()])]),_vm._v(" "),(_vm.showChannelTitle)?_c('div',{staticClass:"comment__channel-title d-md-flex w-100 st_accent-medium-small mt-2 mt-md-0"},[_c('i',{staticClass:"fas fa-arrow-right mr-1 mt-1 st_none-none-smaller comment__channel-title-icon"}),_vm._v("\n      "+_vm._s(_vm.comment.channelInfo.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{ref:"content",staticClass:"mb-2 st_heading-none-base break-word-pre-line comment__content",class:{
        'comment__content--hidden-text': _vm.showFullTextBtn && !_vm.expandContent,
        'mt-2': !_vm.showChannelTitle,
        'mt-1': _vm.showChannelTitle
      },domProps:{"innerHTML":_vm._s(_vm.$utils.autoLinkText(_vm.comment.content))}}),_vm._v(" "),_c('div',{staticClass:"comment__quick-reply st_link-none-small"},[_c('div',{staticClass:"m-n1 d-flex align-items-center flex-wrap"},_vm._l((_vm.quicklyReplies),function(quicklyReply){return _c('span',{key:quicklyReply.identity,staticClass:"p-1 cursor-pointer",domProps:{"textContent":_vm._s(("#" + (quicklyReply.title)))},on:{"click":function($event){$event.preventDefault();return _vm.$emit('addFilterQuicklyReply', quicklyReply.identity)}}})}),0)]),_vm._v(" "),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"comment__updated st_caption-none-small d-flex justify-content-between align-items-end",class:{
        'mt-3': _vm.showFullTextBtn || _vm.comment.image
      }},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.comment.image)?_c('b-img-lazy',{staticClass:"comment__image cursor-pointer",attrs:{"src":_vm.comment.image,"fluid":""}}):_vm._e(),_vm._v(" "),_c('span',[(_vm.showFullTextBtn)?_c('b-button',{staticClass:"border-0 comment__expand-btn st_caption-none-small st_background_gray_lighter",class:{
              'mt-3': _vm.comment.image
            },attrs:{"variant":"none"},on:{"click":function($event){$event.preventDefault();_vm.expandContent = !_vm.expandContent}}},[_vm._v("\n            "+_vm._s(_vm.expandContent ? '顯示部分' : '全部...')+"\n          ")]):_vm._e()],1)],1),_vm._v("\n      "+_vm._s(_vm.$moment(_vm.comment.channel.inputtedAt).format('YYYY/MM/DD'))+"\n    ")]),_vm._v(" "),((_vm.isChannelOwner || _vm.isAdmin) && !_vm.haveReplied)?[(!_vm.isReplying)?_c('div',{staticClass:"d-flex justify-content-end mt-2 mt-md-3"},[_c('b-button',{staticClass:"st_main_m",on:{"click":function($event){$event.preventDefault();_vm.isReplying = !_vm.isReplying}}},[_vm._v("\n          回覆\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.isReplying)?[_c('div',{staticClass:"replyForm"},[_c('div',{staticClass:"basicPostCommentForm position-relative mt-3"},[_c('textarea-autosize',{staticClass:"w-100 basicPostCommentForm__textarea st_border st_heading-light-base st-md_heading-none-base",attrs:{"placeholder":_vm.replyPlaceholder},model:{value:(_vm.replyForm.content),callback:function ($$v) {_vm.$set(_vm.replyForm, "content", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"replyForm.content"}}),_vm._v(" "),(!_vm.replyForm.image)?_c('b-button',{staticClass:"basicPostCommentForm__upload-btn position-absolute p-0 st_color_gray_light",attrs:{"variant":"none"},on:{"click":function($event){$event.preventDefault();return _vm.$refs['uploadImage'].$el.click()}}},[_c('i',{staticClass:"fa fa-image basicPostCommentForm__upload-icon"})]):_vm._e(),_vm._v(" "),_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"uploadImage",staticClass:"d-none",attrs:{"accept":"image/*","plain":""},on:{"change":_vm.onFileChange},model:{value:(_vm.replyForm.imageFile),callback:function ($$v) {_vm.$set(_vm.replyForm, "imageFile", $$v)},expression:"replyForm.imageFile"}}),_vm._v(" "),_c('div',{staticClass:"d-flex",class:{
                'justify-content-between': _vm.replyForm.image,
                'justify-content-end': !_vm.replyForm.image,
                'mt-2': _vm.replyForm.image
              }},[(_vm.replyForm.image)?_c('div',{staticClass:"basicPostCommentForm__preview position-relative"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"image-preview cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.expandImage($event)}}},[_c('b-img-lazy',{staticClass:"basicPostCommentForm__image preview",attrs:{"src":_vm.replyForm.image,"alt":"預覽","fluid":""}}),_vm._v(" "),_c('span',{staticClass:"btn-clear d-inline-block",on:{"click":function($event){$event.preventDefault();return _vm.removeImage($event)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("\n                      clear\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"btn-expand pointer"},[_c('i',{staticClass:"material-icons"},[_vm._v("\n                      zoom_out_map\n                    ")])])],1),_vm._v(" "),_c('span',{staticClass:"basicPostCommentForm__clear-btn position-absolute d-inline-block cursor-pointer rounded-circle d-flex align-items-center justify-content-center",on:{"click":function($event){$event.preventDefault();return _vm.removeImage($event)}}},[_c('i',{staticClass:"material-icons basicPostCommentForm__icon"},[_vm._v("\n                    clear\n                  ")])])]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end",class:{
            'reply__btn position-relative': _vm.replyForm.image !== ''
          }},[_c('b-button',{staticClass:"st_sub_m mr-2",on:{"click":function($event){$event.preventDefault();return _vm.resetReplyForm($event)}}},[_vm._v("\n            取消\n          ")]),_vm._v(" "),_c('b-button',{staticClass:"st_main_m m-0",attrs:{"disabled":_vm.replyForm.content.length < 1 || _vm.isSubmitting},on:{"click":function($event){$event.preventDefault();return _vm.replyTheComment($event)}}},[_vm._v("\n            送出\n          ")])],1)]:_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.comment.repliesInfo),function(reply,replyIndex){return _c('Reply',{key:reply.identity,staticClass:"comment__reply",class:{
        'mt-3 mt-md-2': replyIndex + 1 === 1,
        'mt-2': replyIndex + 1 === _vm.comment.repliesInfo.length
      },attrs:{"onlyView":_vm.onlyView,"reply":reply},on:{"remove":_vm.removeReply}})}),_vm._v(" "),_c('ReviewRemoveModal',{attrs:{"title":"確定移除這則評論","description":"若發現這則評論已違反了評論規則，可以將它移除","data":_vm.comment},on:{"remove":_vm.removeComment}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }