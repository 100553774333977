<template>
  <div
    :class="{
      'channelCardWrap--sidbar': mobileStyle,
    }"
    class="channelCardWrap mx-auto" v-if="channel">
    <span v-if="showExpertName" class="d-flex align-items-center mb-2">
      <svg-icon
        :name="$userBadge(channel.account, 'name')"
        :width="$userBadge(channel.account, 'width', '', 0.43)"
        :height="$userBadge(channel.account, 'height', '', 0.43)"
        class="mr-1" />
      <span class="text-ellipsis">
        <nuxt-link
          class="st_title-medium-base st-md_title-medium-big channelCard__name"
          :to="$userRoute({ name: 'u-uid' }, channel.account)">
          {{ channel.account.name.nick }}{{ (channel.account.belongOrg && _.get(channel.account, 'name.real', '')) ? `．${channel.account.name.real || ''}` : '' }}
        </nuxt-link>
      </span>
    </span>
    <div
      :class="{
        'pb-4': !(isMaximumChannel && canSubscribe),
        'pb-2': isMaximumChannel && canSubscribe,
        'channelCard--sidbar': mobileStyle,
        'pl-3 pr-3 pb-md-0 pl-md-2 pl-lg-4 pr-md-2 pr-lg-3': !mobileStyle
      }"
      class="overflow-hidden channelCard st_background_white position-relative">
      <b-row>
        <b-col
          :class="{
            'pr-md-2 py-md-4': !mobileStyle
          }"
          class="d-flex flex-column">
          <div
            :class="{
              'pr-md-2 pr-lg-4': !mobileStyle
            }">
            <span
              @click.prevent="showDetail = true"
              :class="{
                'st-md_accent-medium-bigger': !mobileStyle
              }"
              class="st_accent-medium-big mb-2 channelCard__title cursor-pointer text-break">
              <span v-if="isSitca" class="sitcaBadge" v-b-tooltip.hover.focus.top="channel.account.belongOrgInfo.name.nick">
                <svg-icon
                  class="sitcaBadge__icon"
                  :width="$userBadge(channel.account.belongOrgInfo, 'width')"
                  :height="$userBadge(channel.account.belongOrgInfo, 'height')"
                  :name="$userBadge(channel.account.belongOrgInfo, 'name')" />
                <span class="sitcaBadge__text">投顧</span>
              </span>
              {{ channel.title }}
            </span>
            <span
              @click.prevent="showDetail = true"
              class="st_none-none-small mb-3 channelCard__content cursor-pointer text-break">
              {{ $utils.filterAllHtmlTags(channel.content) }}
            </span>
            <span
              :class="{
                'align-items-md-center': !mobileStyle
              }"
              class="d-flex align-items-center justify-content-between">
              <span
                :class="{
                  'st-md_link-medium-base': !mobileStyle
                }"
                class="channelCard__more st_link-medium-small cursor-pointer"
                @click.prevent="showDetail = true">
                了解更多
              </span>
              <span
                :class="{
                  'st-md_none-none-small': !mobileStyle
                }"
                class="channelCard__trial st_none-none-smaller px-2 py-1" v-if="hasFreeTrial">
                {{ `免費試閱 ${channel.trial.time} 天` }}
              </span>
            </span>
          </div>
        </b-col>
        <b-col
          :class="{
            'mt-md-0 py-md-3': !mobileStyle
          }"
          class="channelCard__right position-relative">
          <div
            :class="{
              'justify-content-between': !hideSubscription,
              'justify-content-end': hideSubscription,
              'mx-md-0 mb-md-3 st-md_none-none-small': !mobileStyle
            }"
            class="d-flex align-items-center mx-n1 st_color_gray st_none-none-smaller">
            <span
              class="d-flex align-items-center "
              v-if="!hideSubscription">
              <i class="fab fa-hotjar mr-1" />
              {{ channel.subscriptions.toLocaleString() }}
            </span>

            <span>
              <b-button
                :disabled="disableCollectButton"
                @click.prevent="updateCollectionChannelState"
                class="st_background_transparent p-0 border-0 channelCard__bookmark">
                <i
                  :class="collectionChannelState ? 'st_color_sub' : 'st_color_gray_light'"
                  class="fas fa-bookmark" />
              </b-button>
            </span>
          </div>

          <div class="d-flex flex-column align-items-center text-center">
            <span
              :class="{
                'mb-md-1': !mobileStyle
              }"
              class="st_none-medium-small">
              <span
                :class="{
                  'st-md_accent-regular-biggest': !mobileStyle
                }"
                class="st_accent-regular-bigger channelCard__price">
                ${{ channel.price.toLocaleString() }}
              </span>
              <span class="channelCard__renewal">
                /{{ (singleSubscribe && singleSubscribe !== 'month') ? '每' : '' }}{{ channel.unitWord && channel.unitWord !== '' ? channel.unitWord : renewalText }}
              </span>
            </span>

            <component
              class="st_gold_l mt-3 channelCard__subscription-btn w-100"
              :class="{
                'channelCard__subscription-btn--subscribe': subscriptionState,
                'disabled': (subscriptionState || disableSubscribe || isComingSoon || !canSubscribe),
                'cursor-pointer': hasOutsideUrl && !(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe),
              }"
              :to="$userRoute({
                name:'u-uid-service-product-pid',
                params: {
                  pid: channel.identity
                }
              }, channel.account)"
              @click.prevent="(hasOutsideUrl && !(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe)) ? $root.$emit('bv::show::modal', `channelOutsideUrlModal_${channel.identity}`) : undefined"
              :is="(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe || hasOutsideUrl) ? 'span' : 'nuxt-link'">
              <span v-if="subscriptionState">
                已訂閱
                <i class="fas fa-check" />
              </span>
              <span v-else-if="disableSubscribe">
                關閉中
              </span>
              <span v-else-if="isComingSoon">
                即將開放
              </span>
              <span v-else-if="!subscriptionState && canSubscribe">
                <i v-if="hasOutsideUrl" class="fas fa-external-link-alt" /> {{ hasFreeTrial ? '免費試閱' : '立即訂閱' }}
              </span>
              <span v-else>
                訂閱已滿
              </span>
            </component>
            <span
              class="st_color_error st_none-none-smaller mt-2 channelCard__maximum"
              v-if="isMaximumChannel && canSubscribe">
              剩 {{ (channel.maximum - channel.subscriptions).toLocaleString() }} 名額
            </span>
          </div>
        </b-col>
      </b-row>
      <ChannelDetailModal
        v-if="showDetail"
        :channel="channel"
        :disableCollectButton="disableCollectButton"
        :collectionChannelState="collectionChannelState"
        :subscriptionState="subscriptionState"
        :hasFreeTrial="hasFreeTrial"
        :canSubscribe="canSubscribe"
        :isMaximumChannel="isMaximumChannel"
        :isComingSoon="isComingSoon"
        :disableSubscribe="disableSubscribe"
        :hideSubscription="hideSubscription"
        :renewalText="renewalText"
        :isSitca="isSitca"
        :hasOutsideUrl="hasOutsideUrl"
        @shown="checkChannelDetailQuery(false, 'show')"
        @hidden="checkChannelDetailQuery(false, 'hide')"
        @updateCollectionChannel="updateCollectionChannelState" />
      <ChannelOutsideUrlModal :channel="channel" />
    </div>
  </div>
</template>

<script>
  import ChannelDetailModal from '~/components/modal/ChannelDetailModal'
  import ChannelOutsideUrlModal from '~/components/modal/ChannelOutsideUrlModal'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      ChannelDetailModal,
      ChannelOutsideUrlModal,
    },
    props: {
      channel: {
        type:     Object,
        required: true,
      },
      mobileStyle: {
        type:    Boolean,
        default: false,
      },
      showExpertName: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      return {
        collectionChannel:    null,
        disableCollectButton: false,
        subscription:         null,
        showDetail:           false,
      }
    },
    computed: {
      ...mapGetters(['user']),
      collectionChannelState () {
        return !_.isNil(this.collectionChannel) || false
      },
      subscriptionState () {
        return !_.isNil(this.subscription) || false
      },
      hasFreeTrial () {
        return (
          this.channel.trial
          && this.channel.trial.time > 0
        )
      },
      canSubscribe () {
        return this.channel.maximum === -1 || this.channel.maximum > this.channel.subscriptions
      },
      isMaximumChannel () {
        return this.channel.maximum !== -1
      },
      isComingSoon () {
        return this.channel.published > this.$moment().valueOf()
      },
      disableSubscribe () {
        return (!this.channel.enabled || (this.channel.autoRenewal === -1 && this.$moment().valueOf() > this.channel.expired) || this.channel.offShelf)
      },
      hideSubscription () {
        return !!(
          this.channel.hidden
          && this.channel.hidden.subscriptions
        )
      },
      singleSubscribe () {
        if (this.channel.autoRenewal === -1 || this.channel.convertAutoRenewalToSingle) {
          return this.channel.autoRenewal === -1 ? 'expired' : 'month'
        }
        return false
      },
      isSitca () {
        return _.get(this.channel, 'account.belongOrgInfo.organization.kind', '') === 'sitca'
      },
      hasOutsideUrl () {
        return _.get(this.channel, 'outsideUrl', null)
      },
      renewalText () {
        let text = '月'
        if (!this.channel.convertAutoRenewalToSingle) {
          switch (this.channel.autoRenewal) {
            case 3:
              text = '季'
              break
            case 6:
              text = '半年'
              break
            case 12:
              text = '年'
              break
            case -1:
              text = '次'
              break
          }
        }
        return text
      },
      collectionOwner () {
        return this.user[this.$userRouteActive('-collection-service') && !this.$userCheck.isSame() ? 'user_info' : 'my_info']
      },
    },
    methods: {
      ...mapActions('api/subscription', ['getSubscription']),
      ...mapActions('api/collection', ['getCollection', 'createCollection', 'deleteCollection']),
      async updateCollectionChannelState () {
        if (this.$userCheck.isLogin()) {
          if (!this.disableCollectButton) {
            this.disableCollectButton = true
            const { collectionChannel } = this
            if (_.isNil(collectionChannel)) {
              this.collectionChannel = true
              const collection = {
                kind:    'channel',
                index:   this.channel.identity,
                account: this.collectionOwner.identity,
              }
              this.collectionChannel = await this.createCollection(collection)
                .then(data => (_.isNil(data.error) ? data.identity : null))
            } else {
              this.collectionChannel = null
              const collectionOption = {
                identity: collectionChannel,
              }
              await this.deleteCollection(collectionOption)
            }
            this.disableCollectButton = false
          }
        } else {
          this.$toast.show('登入後即可收藏服務項目')
            .goAway(1500)
        }
      },
      checkChannelDetailQuery (checkAutoShow = false, state = 'hide') {
        if (this.$userRouteActive('-service')) {
          const nowQuery = _.get(this, '$route.query.channelDetail')
          if (checkAutoShow && this.channel.identity === nowQuery) {
            this.showDetail = true
          } else if (!checkAutoShow) {
            const newRoute = {
              query: _.cloneDeep(this.$route.query),
            }
            switch (state) {
              case 'show':
                newRoute.query.channelDetail = this.channel.identity
                break
              case 'hide':
                delete newRoute.query.channelDetail
                this.showDetail = false
                break
            }
            this.$router.replace(newRoute).catch(() => {})
          }
        } else if (state === 'hide') {
          this.showDetail = false
        }
      },
    },
    mounted () {
      this.checkChannelDetailQuery(true)
      if (this.$userCheck.isLogin()) {
        const subscriptionOption = {
          account: this.collectionOwner.identity,
          channel: this.channel.identity,
          expired: 'now',
        }
        this.getSubscription(subscriptionOption)
          .then(subscription => {
            this.subscription = !subscription.error ? subscription.identity : null
          })

        const collectionChannelOption = {
          kind:    'channel',
          index:   this.channel.identity,
          account: this.collectionOwner.identity,
        }
        this.getCollection(collectionChannelOption)
          .then(collection => {
            this.collectionChannel = !collection.error ? collection.identity : null
            this.disableCollectButton = false
          })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .channelCardWrap {
    &:not(&--sidbar) {
      max-width: 320px;

      @include media-breakpoint-up(sm) {
        max-width: 456px;
      }

      @include media-breakpoint-up(md) {
        max-width: 608px;
      }
    }
  }

  .channelCard {
    border-radius: 4px;
    min-height: 280px;
    box-shadow: 0 0 6px 0 #b6b9be;
    padding-top: 22px;
    $channelCard: &;

    &--sidbar {
      box-shadow: none;
      padding: 22px 30px 0 30px;
    }

    &:not(&--sidbar) {
      @include media-breakpoint-up(md) {
        border: 1px solid $st_color_sub_light;
        min-height: 182px;
        box-shadow: none;
        padding-top: 0;

        &::before {
          position: absolute;
          right: 220px;
          top: 22px;
          border-right: 2px solid $st_color_gray_lighter;
          height: calc(100% - 44px);
          max-height: 164px;
          content: "";
        }
      }

      .#{channelCard}__subscription-btn {
        @include media-breakpoint-up(md) {
          max-width: 160px;
          padding: 3px 0;
        }
      }

      .#{channelCard}__right {
        @include media-breakpoint-up(md) {
          flex-basis: 220px;
          max-width: 220px;

          &::before {
            display: none;
          }
        }
      }

      .#{channelCard}__title {
        @include media-breakpoint-up(md) {
          letter-spacing: 2px;
        }
      }

      .#{channelCard}__more {
        @include media-breakpoint-up(md) {
          letter-spacing: 1.52px;
        }
      }

      .#{channelCard}__price {
        @include media-breakpoint-up(md) {
          letter-spacing: 2.29px;
        }
      }

      .#{channelCard}__trial {
        @include media-breakpoint-up(md) {
          letter-spacing: 0.8px;
        }
      }
    }

    &__name {
      &:hover {
        color: $st_color_main_light;
      }
    }

    &__title,
    &__content {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__title {
      letter-spacing: 1.71px;
    }

    &__content {
      color: rgba($st_color_gray_darker, 0.71);
    }

    &__more {
      letter-spacing: 1.33px;
    }

    &__trial {
      color: #ff9425;
      background-color: rgba(#ff9425, 0.1);
      letter-spacing: 0.69px;
    }

    &__bookmark {
      font-size: 19px;
      line-height: 19px;

      &:hover,
      &:disabled,
      &:active {
        background-color: $st_color_transparent;
      }
    }

    &__renewal {
      color: rgba(#535353, 0.62);
      letter-spacing: 1.33px;
    }

    &__price {
      letter-spacing: 2px;
    }

    &__subscription-btn {
      min-width: auto;
      max-width: 235px;
      padding: 5px 0;

      &--subscribe {
        background-color: $st_color_transparent;
        border: 2px solid $st_color_gray_lighter;
      }
    }

    &__maximum {
      letter-spacing: 0.69px;
    }

    &__right {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 20px;
      padding-top: 10px;

      &::before {
        position: absolute;
        right: 16px;
        top: 0;
        border-top: 2px solid $st_color_gray_lighter;
        width: calc(100% - 32px);
        content: "";
      }
    }
  }

  .sitcaBadge {
    background-color: rgba($st_color_gray_lighter, 0.3);
    font-size: 14px;
    padding: 4px 10px;
  }
</style>
