<template>
  <transition appear @appear="handleEnter">
    <div class="st_background_white comment">
      <!-- 移除用戶評價 (Mobile) -->
      <div v-if="isAdmin" class="d-md-none frame-dot comment__tool text-right mt-n2">
        <b-dropdown id="userdown" right noCaret class="bg-none border-none mt-n2">
          <template slot="button-content">
            <i class="st_disable-none-big fas fa-cog cursor-pointer" />
          </template>
          <b-dropdown-item-button @click.prevent="$root.$emit('bv::show::modal', comment.identity)">
            移除評論
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center comment__name">
          <nuxt-link :to="{name:'u-uid', params:{uid: comment.account.serial}}">
            <ScanTraderAvatar width="40" height="40" class="mr-2" :src="comment.account.avatar" lazy />
          </nuxt-link>
          <nuxt-link :to="{name:'u-uid', params:{uid: comment.account.serial}}" class="st_title-none-base text-ellipsis">
            {{ comment.account.name.nick }}{{ (comment.account.belongOrg && _.get(comment.account, 'name.real', '')) ? `．${comment.account.name.real || ''}` : '' }}
          </nuxt-link>
        </div>
        <div class="d-flex align-items-center">
          <div
            :class="{
              'st_color_sub': ['good', 'mixed'].includes(level.id),
              'st_color_gray_dark': level.id === 'bad',
              'mr-md-1': isAdmin
            }"
            class="st_none-none-small d-flex align-items-center comment__level text-nowrap">
            <svg-icon class="mr-1" width="20" height="20" :name="level.icon" />
            {{ level.text }}
          </div>
          <!-- 移除用戶評價 (PC) -->
          <div v-if="isAdmin" class="d-none d-md-block frame-dot ml-2 comment__tool">
            <b-dropdown id="userdown" right noCaret class="bg-none border-none">
              <template slot="button-content">
                <i class="st_disable-none-big fas fa-cog cursor-pointer" />
              </template>
              <b-dropdown-item-button @click.prevent="$root.$emit('bv::show::modal', comment.identity)">
                移除評論
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div v-if="showChannelTitle" class="comment__channel-title d-md-flex w-100 st_accent-medium-small mt-2 mt-md-0">
        <i class="fas fa-arrow-right mr-1 mt-1 st_none-none-smaller comment__channel-title-icon" />
        {{ comment.channelInfo.title }}
      </div>
      <div
        :class="{
          'comment__content--hidden-text': showFullTextBtn && !expandContent,
          'mt-2': !showChannelTitle,
          'mt-1': showChannelTitle
        }"
        ref="content"
        class="mb-2 st_heading-none-base break-word-pre-line comment__content" v-html="$utils.autoLinkText(comment.content)" />
      <div class="comment__quick-reply st_link-none-small">
        <div class="m-n1 d-flex align-items-center flex-wrap">
          <span
            v-text="`#${quicklyReply.title}`" v-for="quicklyReply in quicklyReplies" :key="quicklyReply.identity"
            class="p-1 cursor-pointer" @click.prevent="$emit('addFilterQuicklyReply', quicklyReply.identity)" />
        </div>
      </div>
      <div
        :class="{
          'mt-3': showFullTextBtn || comment.image
        }"
        v-viewer
        class="comment__updated st_caption-none-small d-flex justify-content-between align-items-end">
        <div class="d-flex flex-column">
          <b-img-lazy v-if="comment.image" :src="comment.image" fluid class="comment__image cursor-pointer" />
          <span>
            <b-button
              v-if="showFullTextBtn"
              @click.prevent="expandContent = !expandContent"
              :class="{
                'mt-3': comment.image
              }"
              class="border-0 comment__expand-btn st_caption-none-small st_background_gray_lighter" variant="none">
              {{ expandContent ? '顯示部分' : '全部...' }}
            </b-button>
          </span>
        </div>
        {{ $moment(comment.channel.inputtedAt).format('YYYY/MM/DD') }}
      </div>
      <!-- Button: 回覆評論 (以達人、達人小編權限或系統管理員身份回覆) -->
      <template v-if="(isChannelOwner || isAdmin) && !haveReplied">
        <div v-if="!isReplying" class="d-flex justify-content-end mt-2 mt-md-3">
          <b-button class="st_main_m" @click.prevent="isReplying = !isReplying">
            回覆
          </b-button>
        </div>
        <template v-if="isReplying">
          <div class="replyForm">
            <div
              class="basicPostCommentForm position-relative mt-3">
              <textarea-autosize
                class="w-100 basicPostCommentForm__textarea st_border st_heading-light-base st-md_heading-none-base"
                :placeholder="replyPlaceholder"
                v-model.trim="replyForm.content" />
              <b-button
                v-if="!replyForm.image"
                @click.prevent="$refs['uploadImage'].$el.click()"
                class="basicPostCommentForm__upload-btn position-absolute p-0 st_color_gray_light"
                variant="none">
                <i class="fa fa-image basicPostCommentForm__upload-icon" />
              </b-button>
              <b-form-file
                ref="uploadImage" @change="onFileChange" v-model="replyForm.imageFile"
                v-show="false" accept="image/*" plain class="d-none" />
              <div
                :class="{
                  'justify-content-between': replyForm.image,
                  'justify-content-end': !replyForm.image,
                  'mt-2': replyForm.image
                }"
                class="d-flex">
                <div
                  v-if="replyForm.image"
                  class="basicPostCommentForm__preview position-relative">
                  <div class="image-preview cursor-pointer" v-viewer @click.prevent="expandImage">
                    <b-img-lazy :src="replyForm.image" alt="預覽" class="basicPostCommentForm__image preview" fluid />
                    <span class="btn-clear d-inline-block" @click.prevent="removeImage">
                      <i class="material-icons">
                        clear
                      </i>
                    </span>
                    <span class="btn-expand pointer">
                      <i class="material-icons">
                        zoom_out_map
                      </i>
                    </span>
                  </div>
                  <span
                    class="basicPostCommentForm__clear-btn position-absolute d-inline-block cursor-pointer rounded-circle d-flex align-items-center justify-content-center"
                    @click.prevent="removeImage">
                    <i class="material-icons basicPostCommentForm__icon">
                      clear
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-end"
            :class="{
              'reply__btn position-relative': replyForm.image !== ''
            }">
            <b-button class="st_sub_m mr-2" @click.prevent="resetReplyForm">
              取消
            </b-button>
            <b-button
              class="st_main_m m-0" @click.prevent="replyTheComment"
              :disabled="replyForm.content.length < 1 || isSubmitting">
              送出
            </b-button>
          </div>
        </template>
      </template>
      <!-- 達人官方 / 微股力官方的回覆訊息 -->
      <Reply
        @remove="removeReply"
        :onlyView="onlyView"
        v-for="(reply, replyIndex) in comment.repliesInfo"
        :key="reply.identity" :reply="reply"
        :class="{
          'mt-3 mt-md-2': replyIndex + 1 === 1,
          'mt-2': replyIndex + 1 === comment.repliesInfo.length
        }"
        class="comment__reply" />

      <ReviewRemoveModal
        title="確定移除這則評論"
        description="若發現這則評論已違反了評論規則，可以將它移除"
        :data="comment" @remove="removeComment" />
    </div>
  </transition>
</template>

<script>
  import Reply from '~/components/views/review/Reply'
  import ReviewRemoveModal from '~/components/modal/ReviewRemoveModal'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      Reply,
      ReviewRemoveModal,
    },
    props: {
      comment: {
        type:     Object,
        required: true,
      },
      commentOption: {
        type:     Array,
        required: true,
      },
      showChannelTitle: {
        type:    Boolean,
        default: false,
      },
      onlyView: {
        type:    Boolean,
        default: true,
      },
    },
    data () {
      return {
        expandContent:         false,
        originalContentHeight: 0,
        isReplying:            false,
        replyForm:             {
          content:   '',
          image:     '',
          imageFile: null,
        },
        isSubmitting: false,
      }
    },
    computed: {
      ...mapGetters(['user']),
      showFullTextBtn () {
        return this.originalContentHeight > 66
      },
      isChannelOwner () {
        return (
          !this.onlyView
          && this.user.serial !== 0
          && (
            this.user.serial === this.comment.channelInfo.account.serial
            || this.$userCheck.haveOwnRules(['admin', 'contentAdmin', 'contentComment'], this.comment.channelInfo.account)
            || (
              this.user.my_info.roles.includes('admin')
              && this.$route.query.forceAsExpert
            )
          )
        )
      },
      isAdmin () {
        return (
          !this.onlyView
          && 'my_info' in this.user
          && Array.isArray(this.user.my_info.roles)
          && this.user.my_info.roles.includes('admin')
          && !this.$route.query.forceAsExpert
        )
      },
      replyPlaceholder () {
        return this.isAdmin
          ? '以官方身份回覆評論給這位訂閱者'
          : '回覆評論給這位訂閱者'
      },
      haveReplied () {
        return this.comment.repliesInfo
          .find(reply => reply.account.serial === (this.isAdmin ? 1 : this.comment.channelInfo.account.serial))
      },
      quicklyReplies () {
        return this.commentOption.flatMap(option => option.quicklyReplies)
          .filter(option => this.comment.channel.quicklyReplies.includes(option.identity))
      },
      level () {
        const returnObject = {
          id:   '',
          text: '',
          icon: '',
        }
        const id = (this.comment.channel && this.comment.channel.level) ? this.comment.channel.level : null
        if (id) {
          returnObject.id = id
          switch (id) {
            case 'good':
              returnObject.text = '好評'
              returnObject.icon = 'icon_evaluate-icon-positive-s'
              break
            case 'mixed':
              returnObject.text = '中評'
              returnObject.icon = 'icon_evaluate-icon-moderate-s'
              break
            case 'bad':
              returnObject.text = '差評'
              returnObject.icon = 'icon_evaluate-icon-negative'
              break
          }
        }
        return returnObject
      },
    },
    methods: {
      ...mapActions('api/reply', ['createReply', 'getReplies', 'deleteReply']),
      ...mapActions('api/comment', ['updateComment']),
      ...mapActions('api/account', ['getAccount']),
      ...mapActions('api/upload', ['uploadFile']),
      resetReplyForm () {
        this.isReplying = false
        this.replyForm.content = ''
        this.removeImage()
      },
      async replyTheComment () {
        this.isSubmitting = true
        const replyAccount = this.isAdmin
          ? await this.getAccount({ serial: 1, fields: ['identity', 'serial'] }).then(data => data.identity)
          : this.comment.channelInfo.account.identity
        if (!replyAccount) {
          this.$toast.show('取得帳號資訊失敗，請再試一次').goAway(5000)
          this.isSubmitting = false
          return
        }
        const reply = {
          comment: this.comment.identity,
          content: this.replyForm.content,
          image:   this.replyForm.image || '',
          account: replyAccount,
        }
        if (this.replyForm.imageFile) {
          const replyImage = await this.uploadFile(this.replyForm.imageFile)
          if (replyImage && (replyImage === '' || replyImage.error)) {
            this.$toast.show('圖片上傳失敗，請再試一次').goAway(5000)
            this.isSubmitting = false
            return
          }
          reply.image = replyImage
        }
        this.createReply(reply).then(data => {
          if (!data.error) {
            this.resetReplyForm()
            this.updateReplyList()
          } else {
            this.$toast.show('評論回覆失敗').goAway(5000)
          }
          this.isSubmitting = false
        })
      },
      removeReply (identity) {
        this.deleteReply({
          identity,
        }).then(data => {
          if (!data.error) {
            this.updateReplyList()
          } else {
            this.$toast.show('刪除回覆失敗').goAway(5000)
          }
        })
      },
      async updateReplyList () {
        const repliesOption = {
          comment: this.comment.identity,
          fields:  ['identity', 'content', 'image', 'comment', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.ownRoles', 'status.created'],
        }
        const replies = await this.getReplies(repliesOption).then(result => ((!result.error && result.data && result.data.length) ? result.data : []))
        const comment = { ...this.comment, replies: replies.length, repliesInfo: replies }
        this.$emit('update', comment)
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        const maxFileSizeMb = 5
        const fileSize = files[0].size / 1024 / 1024

        if (fileSize > maxFileSizeMb) {
          this.$toast.show(`檔案大小不得超過 ${maxFileSizeMb}Mb`).goAway(5000)
          this.removeImage()
          return
        }

        if (files[0].type.match(/image.*/ig)) {
          this.createImage(files[0])
        } else {
          this.removeImage()
        }
      },
      createImage (file) {
        const reader = new FileReader()

        reader.onload = e => {
          this.replyForm.image = e.target.result
        }
        reader.readAsDataURL(file)
      },
      removeImage () {
        this.$refs.uploadImage.$el.value = ''
        this.replyForm.image = ''
        this.replyForm.imageFile = null
      },
      expandImage (e) {
        e.target.parentNode.parentNode.querySelector('.preview').click()
      },
      removeComment (identity) {
        const comment = {
          identity,
          channelDeletedAt: Date.now(),
        }
        this.updateComment(comment).then(data => {
          if (!data.error) {
            this.$emit('delete', this.comment)
          } else {
            this.$toast.show('評論刪除失敗').goAway(5000)
          }
        })
      },
      handleEnter (el, done) {
        if (this.comment.topComment) {
          this.$velocity.animate(el, {
            backgroundColor:      '#FBF3EC',
            backgroundColorAlpha: 1,
          }, {
            easing:   'easeInSine',
            duration: 100,
            delay:    150,
          })
          this.$velocity.animate(el, {
            backgroundColor: '#FFF',
          }, {
            easing:   'easeOutSine',
            duration: 100,
            delay:    100,
          })
          this.$velocity.animate(el, {
            backgroundColor: '#FBF3EC',
          }, {
            easing:   'easeInSine',
            duration: 100,
            delay:    50,
          })
          this.$velocity.animate(el, {
            backgroundColor: '#FFF',
          }, {
            easing:   'easeOutSine',
            duration: 100,
            delay:    100,
          })
          this.$velocity.animate(el, {
            backgroundColor: '#FBF3EC',
          }, {
            easing:   'easeInSine',
            duration: 450,
            delay:    50,
            complete: done,
          })
        } else {
          done()
        }
      },
    },
    mounted () {
      this.originalContentHeight = this.$refs.content.clientHeight
    },
  }
</script>

<style lang="scss" scoped>
  .comment {
    padding: 15px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 10px 0;
      margin-bottom: 0;
    }

    &__content,
    &__quick-reply,
    &__updated,
    &__channel-title,
    &__reply,
    &__form,
    .replyForm {
      padding-left: 20px;

      @include media-breakpoint-up(md) {
        padding-left: 45px;
      }
    }

    &__channel-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        text-overflow: unset;
        white-space: unset;
        overflow: auto;
        max-width: 440px;
      }
    }

    &__channel-title-icon {
      line-height: 12px;
    }

    &__content {
      &--hidden-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &__name {
      min-width: 0;
    }

    &__level {
      margin-left: 60px;

      @include media-breakpoint-up(md) {
        margin-left: 63px;
      }
    }

    &__expand-btn {
      padding: 5px 12px;
      border-radius: 1px;

      &:hover {
        color: $st_color_gray_dark;
      }
    }

    &__image {
      max-width: 180px;

      @include media-breakpoint-up(md) {
        max-width: 200px;
      }
    }

    &__tool {
      /deep/ {
        .dropdown {
          &.show {
            .dropdown-toggle {
              i {
                color: $st_color_main_lightest;
              }
            }
          }

          .dropdown-toggle {
            line-height: 0;
            padding: 0;

            @include media-breakpoint-up(md) {
              margin-right: 0;
            }
          }
        }

        .dropdown-menu {
          padding: 8px 0;
          min-width: 205px;
          box-shadow: 0 0 6px 0 $st_color_gray_light;
          border-radius: 2px;

          &::before {
            display: none;
          }

          @include media-breakpoint-up(md) {
            @important {
              left: -2px;
              top: -2px;
            }
          }

          .dropdown-item {
            padding: 12px 27px;
            color: $st_color_main_light;

            &:hover {
              background-color: $st_color_gray_lighter;
            }
          }
        }
      }
    }
  }

  .reply {
    &__btn {
      @include media-breakpoint-up(md) {
        margin-top: -40px;
        z-index: 1;
      }
    }
  }

  .image-preview {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    line-height: 200px;
    overflow: hidden;
    text-align: center;
    border-color: rgba($st_color_gray_light, .35);
    border-style: dashed;
    border-width: 2px;
    background-color: white;
    z-index: 2;

    img {
      position: relative;
      max-width: 200px;
      max-height: 200px;
      vertical-align: middle;
      transition: .4s;
      z-index: -2;
    }

    .btn-clear {
      cursor: pointer;
      border-radius: 50%;
      color: white;
      width: 26px;
      height: 26px;
      padding-top: 2px;
      text-align: center;
      background-color: rgba(43, 44, 55, 0.5);
      right: 5px;
      top: 5px;
      position: absolute;
      z-index: 1;
      line-height: 0;

      i {
        font-size: $st_font-size-bigger;
      }
    }

    .btn-expand {
      border-radius: 50%;
      position: absolute;
      width: 26px;
      height: 26px;
      padding-top: 1px;
      text-align: center;
      left: 5px;
      top: 5px;
      color: white;
      background-color: rgba(43, 44, 55, 0.5);
      transition-property: top, left, transform, width, height;
      transition-duration: .4s;
      line-height: 0;

      i {
        transition-property: font-size;
        transition-duration: .4s;
      }
    }

    &:after {
      transition: .4s;
      content: "";
    }

    &:hover {
      &::after {
        content: "";
        background-color: rgba(0, 0, 0, .4);
        position: absolute;
        z-index: -1;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .btn-expand {
        top: 50%;
        left: 50%;
        transform: translate(-60%, -60%);
        background: transparent;

        i {
          font-size: 36px;
        }
      }
    }
  }

  .basicPostCommentForm {
    $basicPostCommentForm: &;

    &__textarea {
      min-height: 80px;
      padding: 13px 13px 25px 16px;

      &:focus {
        outline: none;
      }

      &::-webkit-input-placeholder {
        color: $st_color_gray_light;
      }

      &::-moz-placeholder {
        color: $st_color_gray_light;
      }

      &:-ms-input-placeholder {
        color: $st_color_gray_light;
      }

      &:-moz-placeholder {
        color: $st_color_gray_light;
      }
    }

    &__upload-btn {
      right: 12px;
      bottom: 17px;
      font-size: 28px;
      line-height: 22px;

      &:hover {
        color: $st_color_gray_light;
      }
    }

    &__upload-icon {
      line-height: inherit;
    }

    &__clear-btn {
      width: 26px;
      height: 26px;
      background-color: rgba($st_color_gray_darker, 0.5);
      right: 8px;
      top: 8px;
      color: $white;
    }

    &__icon {
      font-size: $st_font-size-big;
      font-weight: bolder;
    }
  }
</style>
