<template>
  <b-modal
    v-if="channel"
    :id="`modal-${channel.identity}`"
    centered
    :size="(hasComments || hasArticles || isLoading) ? 'xl' : 'lg'"
    body-class="border-0 rounded-0"
    modal-class="channelDetailModal text-break"
    scrollable
    hideHeader
    hideFooter
    @shown="$emit('shown')"
    @hidden="$emit('hidden')">
    <b-row v-if="!isLoading">
      <b-col cols="12" class="d-md-none">
        <b-button
          class="channelDetailModal__back d-flex align-items-center w-100 st_link-none-small px-3 py-2 my-1 border-0 rounded-0"
          variant="none"
          @click.prevent="$root.$emit('bv::hide::modal', `modal-${channel.identity}`)">
          <i class="fas fa-angle-left st_none-none-big mr-2" />
          返回
        </b-button>
      </b-col>
      <b-col
        :class="{
          'channelDetailModal__left pr-md-4': (hasComments || hasArticles)
        }"
        class="position-relative" cols="12" :md="(hasComments || hasArticles) ? 5 : ''">
        <div class="pt-4 pb-5 pl-3 pr-4 p-md-0 st_background_white st-md_background_transparent">
          <div v-if="!hasComments && !hasArticles" class="d-none d-md-flex justify-content-end mb-2">
            <b-button
              @click.prevent="$root.$emit('bv::hide::modal', `modal-${channel.identity}`)"
              class="st_disable-none-bigger p-0 border-0 channelDetailModal__close mt-n3 mt-md-0 mr-n2 mr-md-0" variant="none">
              <i class="fas fa-times" />
            </b-button>
          </div>
          <div class="channelDetailModal__channel-content mx-auto">
            <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
              <div class="channelDetailModal__expert d-flex align-items-center">
                <ScanTraderAvatar width="28" height="28" class="mr-2" :src="channel.account.avatar" />
                <svg-icon
                  :name="$userBadge(channel.account, 'name')"
                  :width="$userBadge(channel.account, 'width', '', 0.47)"
                  :height="$userBadge(channel.account, 'height', '', 0.47)" class="mr-1" />
                <nuxt-link v-if="$userCheck.is('service', channel.account) && _.get(channel.account, 'belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, channel.account.belongOrgInfo)">
                  <svg-icon
                    v-b-tooltip.hover.focus.top="channel.account.belongOrgInfo.name.nick"
                    class="mr-1"
                    :width="$userBadge(channel.account.belongOrgInfo, 'width')"
                    :height="$userBadge(channel.account.belongOrgInfo, 'height')"
                    :name="$userBadge(channel.account.belongOrgInfo, 'name')" />
                </nuxt-link>
                <nuxt-link
                  class="st_title-none-base text-ellipsis channelDetailModal__expert-name"
                  :to="$userRoute({ name: 'u-uid' }, channel.account)">
                  {{ channel.account.name.nick }}{{ (channel.account.belongOrg && _.get(channel.account, 'name.real', '')) ? `．${channel.account.name.real || ''}` : '' }}
                </nuxt-link>
              </div>
              <b-button
                variant="none"
                :disabled="disableCollectButton"
                @click.prevent="$emit('updateCollectionChannel')"
                class="channelDetailModal__collection st_background_transparent p-0 border-0 ml-5">
                <i
                  :class="collectionChannelState ? 'st_color_sub' : 'st_color_gray_light'"
                  class="fas fa-bookmark" />
              </b-button>
            </div>

            <span
              :class="{
                'mt-md-4': hasComments || hasArticles
              }"
              class="channelDetailModal__title st_accent-medium-big d-inline-block mb-3 mb-md-4 mr-4 mr-md-0">
              {{ channel.title }}
            </span>

            <div
              :class="{
                'channelDetailModal__content--has-data': hasComments || hasArticles
              }"
              class="channelDetailModal__content st_none-none-small st-md_none-none-base mr-3 mr-md-0" v-html="channel.content" />

            <div
              :class="{
                'channelDetailModal__channel-status--has-data': (hasComments || hasArticles)
              }"
              class="channelDetailModal__channel-status d-flex align-items-center pt-md-2 mb-1 mb-md-2 mx-auto">
              <span
                class="d-flex align-items-center st_color_gray st_none-none-smaller st-md_none-none-small mr-2 mr-md-3"
                v-if="!hideSubscription">
                <i class="fab fa-hotjar mr-1" />
                {{ channel.subscriptions.toLocaleString() }}
              </span>
              <span
                class="st_color_error st_none-none-smaller"
                v-if="isMaximumChannel && canSubscribe">
                剩 {{ (channel.maximum - channel.subscriptions).toLocaleString() }} 名額
              </span>
            </div>

            <div class="channelDetailModal__subscription-detail mx-md-auto mb-md-3">
              <div
                :class="{
                  'justify-content-between': hasFreeTrial,
                  'justify-content-center': !hasFreeTrial
                }"
                class="d-flex flex-column-reverse flex-md-row align-items-md-center mb-3">
                <span v-if="hasFreeTrial">
                  <span class="channelDetailModal__trial st_color_warning st_none-none-smaller px-2 py-1">
                    {{ `免費試閱 ${channel.trial.time} 天` }}
                  </span>
                </span>
                <div
                  :class="{
                    'mb-2 mb-md-0': hasFreeTrial
                  }">
                  <span class="st_accent-none-bigger st-md_accent-none-biggest">
                    ${{ channel.price.toLocaleString() }}
                  </span>
                  <span class="st_none-medium-small channelDetailModal__renewal">
                    /每{{ channel.unitWord && channel.unitWord !== '' ? channel.unitWord : renewalText }}
                  </span>
                </div>
              </div>

              <component
                class="channelDetailModal__subscription-btn d-block st_gold_l w-100 text-center"
                :class="{
                  'channelDetailModal__subscription-btn--subscribe': subscriptionState,
                  'disabled': (subscriptionState || disableSubscribe || isComingSoon || !canSubscribe),
                  'cursor-pointer': hasOutsideUrl && !(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe),
                }"
                :to="$userRoute({
                  name: 'u-uid-service-product-pid',
                  params: {
                    pid: channel.identity
                  }
                }, channel.account)"
                @click.prevent="(hasOutsideUrl && !(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe)) ? $root.$emit('bv::show::modal', `channelOutsideUrlModal_${channel.identity}`) : undefined"
                :is="(subscriptionState || disableSubscribe || isComingSoon || !canSubscribe || hasOutsideUrl) ? 'span' : 'nuxt-link'">
                <span v-if="subscriptionState">
                  已訂閱
                  <i class="fas fa-check" />
                </span>
                <span v-else-if="disableSubscribe">
                  關閉中
                </span>
                <span v-else-if="isComingSoon">
                  即將開放
                </span>
                <span v-else-if="!subscriptionState && canSubscribe">
                  <i v-if="hasOutsideUrl" class="fas fa-external-link-alt" /> {{ hasFreeTrial ? '免費試閱' : '立即訂閱' }}
                </span>
                <span v-else>
                  訂閱已滿
                </span>
              </component>
            </div>
          </div>
        </div>
      </b-col>
      <b-col v-if="hasComments || hasArticles" class="pl-md-1 pr-md-0" cols="12" md="7">
        <div class="d-none d-md-flex align-items-start justify-content-end px-4 mt-1">
          <b-button
            @click.prevent="$root.$emit('bv::hide::modal', `modal-${channel.identity}`)"
            class="st_disable-none-bigger p-0 border-0 channelDetailModal__close position-relative mt-n3 mt-md-0 mr-n2" variant="none">
            <i class="fas fa-times" />
          </b-button>
        </div>
        <md-tabs
          v-if="hasComments && hasArticles"
          md-dynamic-height
          :md-active-tab="tab"
          @md-changed="tab = $event"
          class="st-tab-bar mt-n2 mt-md-n4 mb-md-4 px-md-4 position-relative channelDetailModal__tabs">
          <md-tab id="comments" md-label="評論" />
          <md-tab id="articles" md-label="文章列表" />
        </md-tabs>
        <div v-else-if="hasComments" class="st_caption-none-bigger st-md_caption-none-biggest mt-n2 mb-1 mt-md-0 mb-md-4 px-4 pb-3 pb-md-0 st_background_white st-md_background_transparent">
          評論
        </div>
        <div v-else class="st_caption-none-bigger st-md_caption-none-biggest mt-n2 mb-1 mt-md-0 mb-md-0 px-4 pb-3 st_background_white st-md_background_transparent">
          文章列表
        </div>
        <div v-show="tab === 'articles'" class="channelDetailModal__articles pb-4 pt-md-4 pb-md-0 pr-md-3">
          <ArticleCardChannel v-for="article in articles" :key="article.identity" :article="article" />
          <div v-if="totalPage.articles > 1" class="text-center text-md-right mt-4 mt-md-5">
            <b-button
              variant="none"
              class="st_link-none-base channelDetailModal__articles-more p-0 mx-4"
              @click.prevent="$root.$emit('bv::hide::modal', `modal-${channel.identity}`)"
              :to="$userRoute({
                name: 'u-uid-service-articles',
                query: {
                  channel: channel.identity
                }
              }, channel.account)">
              全部文章列表
            </b-button>
          </div>
        </div>
        <div v-show="tab === 'comments'" class="channelDetailModal__comments pb-4 pb-md-0">
          <div class="st_background_white pt-3 pt-md-0 pb-2 pb-md-0 px-3 px-md-0 mb-3">
            <div class="px-md-4">
              <b-row class="mx-0 mx-md-n1">
                <b-col
                  v-for="(levelOption, levelOptionIndex) in levelOptions"
                  :key="levelOption.id"
                  :class="{
                    'pr-1': levelOption.id !== 'all' && levelOptionIndex + 1 !== levelOptions.length,
                    'mb-2 mb-md-0': levelOption.id === 'all',
                  }"
                  :cols="levelOption.id === 'all' ? 12 : 4" md="3" class="px-0 px-md-1">
                  <ReviewLevel
                    v-model="level" @change="levelChange" :horizontal="levelOption.id === 'all' && windowWidth < 768"
                    :name="levelOption.text" :count="levelOption.count" :value="levelOption.id">
                    <template slot="icon" v-if="levelOption.icon['normal']">
                      <svg-icon width="18" height="18" :name="levelOption.icon[level.includes(levelOption.id) ? 'selected' :'normal']" />
                    </template>
                  </ReviewLevel>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="px-md-4">
            <div class="my-n1 mx-0 mx-md-n1 d-flex flex-wrap justify-content-center justify-content-md-start">
              <div
                v-for="quicklyRepliesOption in quicklyRepliesOptions"
                :key="quicklyRepliesOption.identity"
                class="pr-1 py-1 px-md-1">
                <NormalTag
                  v-model="quicklyReplies"
                  @change="quicklyRepliesChange"
                  :name="`${quicklyRepliesOption.title}(${quicklyRepliesOption.count})`"
                  :value="quicklyRepliesOption.identity" />
              </div>
            </div>
          </div>
          <div v-if="comments && comments.length" class="px-md-4 mt-3 mt-md-4">
            <Comment
              v-for="comment in comments" :key="comment.identity"
              @addFilterQuicklyReply="addFilterQuicklyReply"
              :commentOption="commentOption"
              :comment="comment" />
            <div v-if="totalPage.comments > 1" class="text-center text-md-right mt-4 mt-md-5 mb-md-5 pb-md-4">
              <b-button
                variant="none"
                class="st_link-none-base channelDetailModal__comments-more p-0"
                @click.prevent="$root.$emit('bv::hide::modal', `modal-${channel.identity}`)"
                :to="$userRoute({
                  name: 'u-uid-service-review',
                  query: {
                    channel: channel.identity,
                    level: (level.length !== 1 || !level.includes('all')) ? level.join(',') : undefined,
                    quicklyReplies: quicklyReplies.length ? quicklyReplies.join(',') : undefined,
                    reload: needForceReload ? true : undefined,
                  }
                }, channel.account)">
                全部{{ totalComment }}則評論
              </b-button>
            </div>
          </div>
          <div v-else-if="comments" class="px-3 px-md-4 text-center my-5">
            <span class="st_none-none-big st-md_none-none-biggest">沒有評價喔，您可嘗試選擇其他篩選條件</span>
          </div>
          <div v-else class="px-md-4 mt-3 mt-md-4">
            <ReviewLoading
              v-for="item in 2" :key="`ReviewLoading-${item}`"
              :class="{
                'mb-2 mb-md-4': item !== 2
              }" />
          </div>
        </div>
      </b-col>
    </b-row>
    <ChannelDetailContentLoading v-else />
  </b-modal>
</template>

<script>
  import ArticleCardChannel from '~/components/cards/ArticleCardChannel'
  import ChannelDetailContentLoading from '~/components/placeholder/ChannelDetailContentLoading'
  import Comment from '~/components/views/review/Comment'
  import NormalTag from '~/components/filters/NormalTag'
  import ReviewLevel from '~/components/filters/ReviewLevel'
  import ReviewLoading from '~/components/placeholder/ReviewLoading'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      ArticleCardChannel,
      ChannelDetailContentLoading,
      Comment,
      NormalTag,
      ReviewLoading,
      ReviewLevel,
    },
    props: {
      channel: {
        type:     Object,
        required: true,
      },
      disableCollectButton: {
        type:     Boolean,
        required: true,
      },
      collectionChannelState: {
        type:     Boolean,
        required: true,
      },
      subscriptionState: {
        type:     Boolean,
        required: true,
      },
      hasFreeTrial: {
        type:     Boolean,
        required: true,
      },
      canSubscribe: {
        type:     Boolean,
        required: true,
      },
      isMaximumChannel: {
        type:     Boolean,
        required: true,
      },
      isComingSoon: {
        type:     Boolean,
        required: true,
      },
      disableSubscribe: {
        type:     Boolean,
        required: true,
      },
      hideSubscription: {
        type:     Boolean,
        required: true,
      },
      renewalText: {
        type:     String,
        required: true,
      },
      isSitca: {
        type:     String,
        required: true,
      },
      hasOutsideUrl: {
        type:     String,
        required: true,
      },
    },
    data () {
      return {
        tab:       'comments',
        comments:  null,
        articles:  null,
        totalPage: {
          articles: 0,
          comments: 0,
        },
        lastLevel:              ['all'],
        level:                  ['all'],
        quicklyReplies:         [],
        commentOption:          null,
        infiniteHandlerRequest: Date.now(),
        totalComment:           0,
      }
    },
    computed: {
      ...mapGetters(['user']),
      isLoading () {
        return this.commentOption === null || this.articles === null
      },
      hasComments () {
        const allComment = this.levelOptions.find(option => option.id === 'all')
        return !!(allComment && allComment.count)
      },
      hasArticles () {
        return (
          Array.isArray(this.articles)
          && this.articles.length > 0
        )
      },
      levelOptions () {
        if (this.commentOption && this.commentOption.length) {
          const options = [{ ...this.convertLevel('all'), count: _.sumBy(this.commentOption, 'count') || 0 }]
          this.commentOption.map(option => options.push({ ...this.convertLevel(option.level), count: option.count || 0 }))
          return options
        }
        return []
      },
      quicklyRepliesOptions () {
        let options = []
        if (this.level && this.commentOption && this.commentOption.length) {
          const levelOptions = this.commentOption.filter(option => this.level.includes('all') || this.level.includes(option.level))
          if (levelOptions.length) {
            levelOptions.forEach(levelOption => levelOption.quicklyReplies.forEach(quicklyReply => (quicklyReply.count && options.push(quicklyReply))))
            if (options.length) options = _.orderBy(options, 'count', 'desc')
          }
        }
        return options
      },
      needForceReload () {
        return (this.$userRouteActive('-service-review') && (!this.$route.query.channel || this.$route.query.channel === this.channel.identity))
      },
    },
    methods: {
      ...mapActions('api/article', ['getArticlesByAccount']),
      ...mapActions('api/comment', ['getComments']),
      ...mapActions('api/reply', ['getReplies']),
      ...mapActions('api/service', ['getServiceCommentOptions']),
      convertLevel (id, type = 'Object') {
        const returnObject = {
          id:   '',
          text: '',
          icon: {
            normal:   '',
            selected: '',
          },
        }
        if (!_.isNil(id)) {
          returnObject.id = id
          switch (id) {
            case 'all':
              returnObject.text = '全部評價'
              break
            case 'good':
              returnObject.text = '好評'
              returnObject.icon.normal = 'icon_evaluate-icon-filter-positive'
              returnObject.icon.selected = 'icon_evaluate-icon-filter-positive-s'
              break
            case 'mixed':
              returnObject.text = '中評'
              returnObject.icon.normal = 'icon_evaluate-icon-filter-moderate'
              returnObject.icon.selected = 'icon_evaluate-icon-filter-moderate-s'
              break
            case 'bad':
              returnObject.text = '差評'
              returnObject.icon.normal = 'icon_evaluate-icon-filter-negative'
              returnObject.icon.selected = 'icon_evaluate-icon-filter-negative-s'
              break
          }
        }
        return type === 'Object' ? returnObject : returnObject[type]
      },
      getArticleList () {
        return this.getArticlesByAccount({
          kind:     'all',
          fields:   ['identity', 'title', 'summary', 'view', 'likes', 'commentsReply', 'comments'],
          account:  [this.channel.account.identity],
          channels: [this.channel.identity],
          limit:    20,
        }).then(data => {
          this.articles = !data.error ? data.data : []
          this.totalPage.articles = !data.error ? data.page.total : 0
        })
      },
      getCommentOption () {
        this.commentOption = null
        const serviceOption = {
          channels: this.channel.identity,
        }
        return this.getServiceCommentOptions(serviceOption).then(data => {
          if (!data.error && data.identity && data.commentOption) {
            this.commentOption = data.commentOption
          } else {
            this.$toast.show('讀取快速評論列表錯誤').goAway(5000)
            this.commentOption = []
            this.comments = []
          }
        })
      },
      levelChange () {
        const level = _.cloneDeep(this.level)
        const lastLevel = _.cloneDeep(this.lastLevel)
        let currentLevel = ['all']
        if (lastLevel.length !== level.length) {
          if (lastLevel.length === 1 && lastLevel.includes('all') && level.length > 1) {
            currentLevel = level.filter(option => option !== 'all')
          } else if (!lastLevel.includes('all') && !level.includes('all') && level.length && level.length < this.levelOptions.length - 1) {
            currentLevel = level
          }
          this.level = _.cloneDeep(currentLevel)
          this.lastLevel = _.cloneDeep(currentLevel)
          this.quicklyReplies = this.quicklyReplies.filter(quicklyReply => this.quicklyRepliesOptions.find(option => option.identity === quicklyReply))
          this.getCommentList()
        }
      },
      quicklyRepliesChange () {
        this.getCommentList()
      },
      addFilterQuicklyReply (identity) {
        if (!this.quicklyReplies.find(quicklyReply => quicklyReply === identity)) {
          this.quicklyReplies.push(identity)
          this.quicklyRepliesChange()
        }
      },
      async getCommentList () {
        if (!this.commentOption || !this.commentOption.length) return
        this.comments = null
        const now = Date.now()
        this.getCommentListRequest = now
        const commentOption = {
          kind:            'channel',
          index:           this.channel.identity,
          channelInputted: true,
          channelDeleted:  false,
          sort:            { 'channel.grade': -1, 'channel.inputtedAt': -1 },
          fields:          ['identity', 'content', 'image', 'account.avatar', 'account.name', 'account.serial', 'index', 'channel.level', 'channel.inputtedAt', 'channel.quicklyReplies'],
          limit:           20,
          page:            1,
        }
        if (this.level.length > 1 || !this.level.includes('all')) commentOption.channelLevel = this.level
        if (this.quicklyReplies.length) commentOption.channelQuicklyReplies = this.quicklyReplies
        this.getComments(commentOption).then(async data => {
          if (data.error) {
            return
          }
          const comments = []
          if (data.data.length) {
            comments.push(...data.data.filter(comment => (this.topComment === null || comment.identity !== this.topComment)))
            const repliesOption = {
              comment: comments.map(comment => comment.identity),
              fields:  ['identity', 'content', 'image', 'comment', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.ownRoles', 'status.created'],
            }
            const replies = await this.getReplies(repliesOption).then(result => ((!result.error && result.data && result.data.length) ? result.data : []))
            comments.forEach((comment, commentIndex) => {
              comments[commentIndex].channelInfo = this.channel || {}
              comments[commentIndex].repliesInfo = replies.filter(reply => reply.comment === comment.identity) || []
            })
            this.totalComment = data.page.count
            this.totalPage.comments = data.page.total
          }
          if (this.getCommentListRequest !== now) return
          if (!this.comments) this.comments = []
          this.comments.push(...comments)
        })
      },
    },
    async mounted () {
      this.$root.$emit('bv::show::modal', `modal-${this.channel.identity}`)
      await Promise.all([this.getArticleList(), this.getCommentOption()])
      if (this.commentOption && this.commentOption.length) {
        await this.getCommentList()
      }
      if (this.hasArticles && !this.hasComments) {
        this.tab = 'articles'
      }
    },
  }
</script>

<style lang="scss">
.channelDetailModal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      max-width: none;
      margin: 0;
      min-height: 100vh;
      align-items: unset;
    }

    @include media-breakpoint-up(md) {
      padding: 0 20px;

      &.modal-xl {
        max-width: 1088px;
      }

      &.modal-lg {
        max-width: 720px;
      }
    }

    .modal-content {
      @include media-breakpoint-down(sm) {
        background-color: #f6f6f6;
        height: 100%;
      }

      .modal-body {
        @include media-breakpoint-down(sm) {
          padding: 0;
          overflow-x: hidden;
        }

        @include media-breakpoint-up(md) {
          box-shadow: 0 0 6px 0 #b6b9be;
        }
      }
    }
  }

  &__close {
    z-index: 1;
    line-height: 21px;

    &:hover {
      color: $st_color_gray_light;
      opacity: 0.8;
    }
  }

  &__channel-content {
    @include media-breakpoint-up(md) {
      max-width: 580px;
    }
  }

  &__expert {
    min-width: 0;
  }

  &__expert-name {
    &:hover {
      color: $st_color_main_light;
    }
  }

  &__collection {
    font-size: 19px;
  }

  &__content {
    margin-bottom: 40px;
    color: rgba($st_color_gray_darker, 0.8);

    @include media-breakpoint-up(md) {
      min-height: 286px;
      margin-bottom: 0;
    }

    &--has-data {
      @include media-breakpoint-up(md) {
        min-height: 392px;
      }
    }

    p {
      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__trial {
    background-color: rgba($st_color_warning, 0.1);
  }

  &__channel-status {
    @include media-breakpoint-up(md) {
      border-top: 2px solid $st_color_gray_lighter;
    }

    &--has-data {
      @include media-breakpoint-up(md) {
        max-width: 358px;
      }
    }
  }

  &__subscription-detail {
    max-width: 200px;

    @include media-breakpoint-up(md) {
      max-width: 300px;
    }
  }

  &__subscription-btn {
    min-width: auto;
    padding: 5px 0;

    &--subscribe,
    &--subscribe.disabled {
      background-color: $st_color_transparent;
      border: 2px solid $st_color_gray_lighter;
    }
  }

  &__left {
    @include media-breakpoint-up(md) {
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: -16px;
        z-index: 1;
        height: calc(100% + 32px);
        width: 4px;
        background-color: $st_color_gray_lighter;
      }
    }
  }

  &__tabs {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #f6f6f6;
      z-index: 1;
    }

    @include media-breakpoint-up(md) {
      &::before {
        display: none;
      }
    }

    &.md-alignment-left {
      .md-tabs-navigation {
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }
    }

    .md-button {
      min-width: 110px;

      @include media-breakpoint-up(md) {
        min-width: 140px;
      }
    }
  }

  &__articles {
    max-height: calc(100vh - 48px);
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
      max-height: 560px;
    }
  }

  &__comments {
    max-height: calc(100vh - 48px);
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
      max-height: 560px;
    }
  }

  &__articles-more,
  &__comments-more {
    &:hover {
      color: $st_color_main_lightest;
    }
  }

  &__title {
    @include media-breakpoint-up(md) {
      max-width: 530px;
    }
  }

  &__back {
    &:hover {
      color: $st_color_main_lightest;
    }
  }

  &__renewal {
    color: rgba(#535353, 0.62);
  }
}
</style>
