var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reply"},[_c('div',{staticClass:"reply__background"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center st_link-medium-small"},[(_vm.isOfficial)?_c('svg-icon',{attrs:{"width":"15.9","height":"15.9","name":"icon_evaluate-icon-reply-admin"}}):_c('svg-icon',{attrs:{"width":"14.6","height":"16.5","name":"icon_evaluate-icon-reply-expert"}}),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v("\n          "+_vm._s(_vm.isOfficial ? '官方' : '達人')+"回覆\n        ")])],1),_vm._v(" "),(_vm.isAdmin || (_vm.isOwner && !_vm.isOfficial))?_c('i',{staticClass:"fas fa-trash st_link-none-big reply__remove cursor-pointer mr-1 mr-md-0",on:{"click":function($event){$event.preventDefault();return _vm.$root.$emit('bv::show::modal', _vm.reply.identity)}}}):_vm._e()]),_vm._v(" "),_c('div',{ref:"content",staticClass:"reply__content st_heading-none-base break-word-pre-line mt-2",class:{
        'reply__content--hidden-text': _vm.showFullTextBtn && !_vm.expandContent
      },domProps:{"innerHTML":_vm._s(_vm.$utils.autoLinkText(_vm.reply.content))}}),_vm._v(" "),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"st_link-none-smaller st-md_link-none-small d-flex justify-content-between align-items-end",class:{
        'mt-3': _vm.showFullTextBtn || _vm.reply.image,
      }},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.reply.image)?_c('b-img-lazy',{staticClass:"reply__image cursor-pointer",attrs:{"src":_vm.reply.image,"fluid":""}}):_vm._e(),_vm._v(" "),_c('span',[(_vm.showFullTextBtn)?_c('b-button',{staticClass:"border-0 reply__expand-btn st_link-none-small st_background_gray_lighter",class:{
              'mt-3': _vm.reply.image
            },attrs:{"variant":"none"},on:{"click":function($event){$event.preventDefault();_vm.expandContent = !_vm.expandContent}}},[_vm._v("\n            "+_vm._s(_vm.expandContent ? '顯示部分' : '全部…')+"\n          ")]):_vm._e()],1)],1),_vm._v(" "),_c('span',{class:{
          'mb-1': _vm.showFullTextBtn
        }},[_vm._v("\n        "+_vm._s(_vm.$moment(_vm.reply.status.created).format('YYYY-MM-DD'))+"\n      ")])])]),_vm._v(" "),_c('ReviewRemoveModal',{attrs:{"title":"確定移除這則回覆","description":"移除回覆後，你可以重新回覆評論","data":_vm.reply},on:{"remove":function($event){return _vm.$emit('remove', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }