<template>
  <md-checkbox
    :disabled="disabled" class="reviewLevel m-0 text-center"
    v-model="selected" :value="value">
    <div
      :class="{
        'reviewLevel__name--horizontal': horizontal
      }"
      class="reviewLevel__name st_color_main_lighter st_none-none-small d-flex align-items-center justify-content-center">
      <slot name="icon" />
      <span
        :class="{
          'ml-2': $slots.icon
        }">
        {{ name }}
      </span>
      <span v-if="horizontal" class="reviewLevel__count reviewLevel__count--horizontal st_caption-none-smaller">
        ({{ count.toLocaleString() }})
      </span>
    </div>
    <span v-if="!horizontal" class="reviewLevel__count st_caption-none-smaller">
      {{ count.toLocaleString() }}
    </span>
  </md-checkbox>
</template>

<script>
  export default {
    model: {
      prop:  'checked',
      event: 'change',
    },
    props: {
      name: {
        type:     String,
        required: true,
      },
      count: {
        type:     Number,
        required: true,
      },
      value: {
        type:     String,
        required: true,
      },
      disabled: {
        type:    Boolean,
        default: false,
      },
      checked: {
        type: [String, Array],
        default () {
          return []
        },
      },
      horizontal: {
        type:    Boolean,
        default: false,
      },
    },
    computed: {
      selected: {
        get () {
          return this.checked
        },
        set (val) {
          this.$emit('change', val)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .reviewLevel {
    user-select: none;
    display: block;
    $reviewLevel: &;

    /deep/ {
      .md-checkbox-container {
        display: none;
      }

      .md-checkbox-label {
        padding: 11px 15px 6px 15px;
        margin: 0;
        background-color: $st_color_gray_lighter;
        border-radius: 2px;
        height: auto;
        width: 100%;

        #{$reviewLevel}__name {
          margin-bottom: 2px;
        }

        &:hover,
        &:active {
          background-color: rgba($st_color_main_lightest, 0.2);
        }
      }
    }

    &.md-checked {
      /deep/ {
        .md-checkbox-label {
          background-color: $st_color_main_lightest;

          #{$reviewLevel}__name:not(#{$reviewLevel}__name--horizontal) {
            font-weight: $st_font-weight-medium;
          }

          #{$reviewLevel}__count {
            font-weight: normal;

            &--horizontal {
              font-weight: $st_font-weight-medium;
            }
          }

          #{$reviewLevel}__name,
          #{$reviewLevel}__count {
            color: $white;
          }
        }
      }
    }
  }
</style>
