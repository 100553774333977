<template>
  <div class="reply">
    <div class="reply__background">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center st_link-medium-small">
          <svg-icon
            v-if="isOfficial"
            width="15.9" height="15.9" name="icon_evaluate-icon-reply-admin" />
          <svg-icon v-else width="14.6" height="16.5" name="icon_evaluate-icon-reply-expert" />
          <span class="ml-1">
            {{ isOfficial ? '官方' : '達人' }}回覆
          </span>
        </div>
        <i
          v-if="isAdmin || (isOwner && !isOfficial)"
          class="fas fa-trash st_link-none-big reply__remove cursor-pointer mr-1 mr-md-0"
          @click.prevent="$root.$emit('bv::show::modal', reply.identity)" />
      </div>
      <div
        :class="{
          'reply__content--hidden-text': showFullTextBtn && !expandContent
        }"
        ref="content" class="reply__content st_heading-none-base break-word-pre-line mt-2" v-html="$utils.autoLinkText(reply.content)" />
      <div
        :class="{
          'mt-3': showFullTextBtn || reply.image,
        }"
        v-viewer
        class="st_link-none-smaller st-md_link-none-small d-flex justify-content-between align-items-end">
        <div class="d-flex flex-column">
          <b-img-lazy v-if="reply.image" :src="reply.image" fluid class="reply__image cursor-pointer" />
          <span>
            <b-button
              v-if="showFullTextBtn"
              @click.prevent="expandContent = !expandContent"
              :class="{
                'mt-3': reply.image
              }"
              class="border-0 reply__expand-btn st_link-none-small st_background_gray_lighter" variant="none">
              {{ expandContent ? '顯示部分' : '全部…' }}
            </b-button>
          </span>
        </div>
        <span
          :class="{
            'mb-1': showFullTextBtn
          }">
          {{ $moment(reply.status.created).format('YYYY-MM-DD') }}
        </span>
      </div>
    </div>
    <ReviewRemoveModal
      title="確定移除這則回覆"
      description="移除回覆後，你可以重新回覆評論"
      :data="reply" @remove="$emit('remove', $event)" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ReviewRemoveModal from '~/components/modal/ReviewRemoveModal'

  export default {
    components: {
      ReviewRemoveModal,
    },
    props: {
      reply: {
        type:    Object,
        default: null,
      },
      onlyView: {
        type:    Boolean,
        default: true,
      },
    },
    data () {
      return {
        expandContent:         false,
        originalContentHeight: 0,
      }
    },
    computed: {
      ...mapGetters(['user']),
      isOfficial () {
        return (
          'account' in this.reply
          && this.reply.account.serial === 1
        )
      },
      isOwner () {
        return (
          !this.onlyView
          && 'account' in this.reply
          && 'my_info' in this.user
          && (
            this.reply.account.serial === this.user.my_info.serial
            || this.$userCheck.haveOwnRules(['admin', 'contentAdmin', 'contentComment'], this.reply.account)
          )
        )
      },
      isAdmin () {
        return (
          !this.onlyView
          && 'my_info' in this.user
          && Array.isArray(this.user.my_info.roles)
          && this.user.my_info.roles.includes('admin')
        )
      },
      showFullTextBtn () {
        return this.originalContentHeight > (this.windowWidth > 768 ? 66 : 88)
      },
    },
    mounted () {
      this.originalContentHeight = this.$refs.content.clientHeight
    },
  }
</script>

<style lang="scss" scoped>
  .reply {
    &__background {
      background-color: rgba($st_color_main_lightest, 0.08);
      padding: 8px 6px 11px 15px;

      @include media-breakpoint-up(md) {
        padding: 17px 11px 10px 17px;
      }
    }

    &__image {
      max-width: 160px;

      @include media-breakpoint-up(md) {
        max-width: 180px;
      }
    }

    &__expand-btn {
      background-color: rgba($st_color_main_lightest, 0.15);
      border-radius: 1px;

      &:hover {
        color: $st_color_main_lightest;
      }
    }

    &__content {
      &--hidden-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        @include media-breakpoint-up(md) {
          -webkit-line-clamp: 3;
        }
      }
    }

    &__remove {
      @include media-breakpoint-up(md) {
        margin-top: -10px;
      }
    }
  }
</style>
