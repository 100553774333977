<template>
  <nuxt-link
    v-if="article"
    class="st_background_white d-block mb-2 mb-md-3"
    :to="{
      name: 'article-id',
      params: {
        id: article.identity
      }
    }">
    <article class="articleCardChannel">
      <header class="articleCardChannel__title st_title-medium-base st-md_title-medium-big mb-2 w-100 text-ellipsis d-block">
        {{ article.title }}
      </header>
      <main class="articleCardChannel__summary w-100 mb-md-4 st_caption-light-smaller st-md_caption-regular-small">
        {{ $utils.filterAllHtmlTags(article.summary) }}
      </main>
      <footer class="articleCardChannel__statistic d-none d-md-flex justify-content-end">
        <span class="st_caption-none-smaller mx-1">
          瀏覽人數 {{ article.view || 0 }}
        </span>
        <span class="st_caption-none-smaller mx-1">
          <i class="material-icons align-middle articleCardChannel__icon">
            thumb_up
          </i>
          {{ article.likes || 0 }}
        </span>
        <span class="st_caption-none-smaller mx-1">
          <i class="material-icons align-middle articleCardChannel__icon">
            chat_bubble
          </i>
          {{ article.commentReply || article.comments || 0 }}
        </span>
      </footer>
    </article>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      article: {
        type:     Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .articleCardChannel {
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 0 28px;
    }

    &__icon {
      font-size: $st_font-size-small;
    }

    &__title,
    &__statistic {
      @include media-breakpoint-up(md) {
        max-width: 540px;
      }
    }

    &__summary {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;

      @include media-breakpoint-up(md) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        white-space: unset;
        max-width: 540px;
      }
    }
  }
</style>
