<template>
  <b-modal
    :id="`channelOutsideUrlModal_${channel.identity}`"
    modal-class="channelOutsideUrlModal"
    dialog-class="mx-auto"
    @shown="goOutsideUrl"
    hideHeader hideFooter centered
    noCloseOnBackdrop noCloseOnEsc noEnforceFocus>
    <b-container class="px-0 text-center" v-if="_.get(channel, 'account.belongOrgInfo', null)">
      <ScanTraderAvatar width="60" height="60" :src="channel.account.belongOrgInfo.avatar" class="mt-3 mb-1" />
      <div class="st_title-medium-bigger">
        前往購買頁面
      </div>
      <div class="st_title-medium-base mb-3">
        跳轉至{{ channel.account.belongOrgInfo.name.nick }}
      </div>
      <BeatLoader :loading="true" color="#d99d6a" :size="15" sizeUnit="px" class="mt-1 mb-2" />
    </b-container>
  </b-modal>
</template>

<script>
  export default {
    props: {
      channel: {
        type:     Object,
        required: true,
      },
    },
    data () {
      return {}
    },
    computed: {
    },
    methods: {
      goOutsideUrl () {
        const outsideUrl = _.get(this.channel, 'outsideUrl', null)
        if (outsideUrl) {
          setTimeout(() => {
            location.href = outsideUrl
          }, 1000)
        } else {
          this.$root.$emit('bv::hide::modal', `channelOutsideUrlModal_${this.channel.identity}`)
        }
      },
    },
    mounted () {
    },
  }
</script>

<style lang="scss">
  .channelOutsideUrlModal {
    &__dialog {
      max-width: 425px;
    }
  }
</style>
