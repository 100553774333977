<template>
  <b-modal
    :id="data.identity"
    :title="title"
    title-class="st_title-medium-big st-md_title-medium-bigger mt-md-2"
    @ok="$emit('remove', data.identity)"
    modal-class="reviewRemoveModal"
    header-class="border-0"
    body-class="st_heading-none-base py-0"
    footer-class="border-0 pt-0"
    centered>
    {{ description }}
    <template slot="modal-footer" slot-scope="modal">
      <b-button class="st_sub_s mr-3" @click.prevent="modal.cancel">
        取消
      </b-button>
      <b-button class="st_main_s m-0" @click.prevent="modal.ok">
        移除
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  export default {
    props: {
      data: {
        type:    Object,
        default: null,
      },
      title: {
        type:    String,
        default: '',
      },
      description: {
        type:    String,
        default: '',
      },
    },
  }
</script>

<style lang="scss">
  .reviewRemoveModal {
    .close {
      color: $st_color_gray_light;
      opacity: 1;

      @include media-breakpoint-down(sm) {
        margin: -24px -24px 0 0;
        padding-bottom: 0;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .modal-content {
      min-height: 180px;
      max-height: 180px;

      @include media-breakpoint-up(md) {
        min-height: 197px;
        max-height: 197px;
      }
    }
  }
</style>
