<template>
  <div class="channelDetailModalLoading">
    <b-row>
      <b-col cols="12" md="5" class="channelDetailModalLoading__left position-relative">
        <div class="d-flex align-items-center mb-4 mb-md-5">
          <div class="channelDetailModalLoading__avatar rounded-circle mr-2" />
          <div class="channelDetailModalLoading__text channelDetailModalLoading__text--name" />
        </div>
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-m mb-md-5" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-l channelDetailModalLoading__text--gap" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-l channelDetailModalLoading__text--gap" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-l channelDetailModalLoading__text--gap-l" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-l channelDetailModalLoading__text--gap" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-l channelDetailModalLoading__text--gap-m d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--description-ml channelDetailModalLoading__text--gap-xl" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--border mx-auto mb-3 d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--subscription channelDetailModalLoading__text--gap ml-md-4" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--price mb-2 mb-md-3 mx-md-auto" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--price mb-2 mb-md-3 mx-md-auto" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--button mx-md-auto mb-5 mb-md-2" />
        <div class="pt-4 d-md-none" />
      </b-col>
      <b-col cols="12" md="7" class="channelDetailModalLoading__right pb-md-1">
        <div class="d-none d-md-flex justify-content-end mb-2">
          <b-button
            class="st_disable-none-bigger p-0 border-0 channelDetailModalLoading__close mr-n3" variant="none">
            <i class="fas fa-times" />
          </b-button>
        </div>
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--tabs mx-auto mx-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--title ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap-ml ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--title ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap-ml ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--title ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap-ml ml-3 ml-md-0" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--title d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap-ml d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--title d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary channelDetailModalLoading__text--gap d-none d-md-block" />
        <div class="channelDetailModalLoading__text channelDetailModalLoading__text--summary d-none d-md-block" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .channelDetailModalLoading {
    padding: 24px 15px;
    background-color: $white;

    @include media-breakpoint-up(md) {
      padding: 4px;
    }

    &__left {
      @include media-breakpoint-up(md) {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          top: -20px;
          z-index: 1;
          height: calc(100% + 40px);
          width: 4px;
          background-color: $st_color_gray_lighter;
        }
      }
    }

    &__right {
      @include media-breakpoint-up(md) {
        padding: 0 28px;
      }
    }

    &__close {
      line-height: 21px;

      &:hover {
        color: $st_color_gray_light;
        opacity: .8;
      }
    }

    &__avatar {
      width: 28px;
      height: 28px;

      @include animation;
    }

    &__text {
      width: 100%;

      @include animation;

      &--name {
        max-width: 160px;
        height: 12px;
        border-radius: 7.5px;
      }

      &--description-m {
        max-width: 280px;
        height: 12px;
        border-radius: 7.5px;
        margin-bottom: 33px;

        @include media-breakpoint-up(md) {
          max-width: 258px;
        }
      }

      &--description-l {
        max-width: 325px;
        height: 8px;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
          max-width: 380px;
        }
      }

      &--description-ml {
        max-width: 250px;
        height: 8px;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
          max-width: 300px;
        }
      }

      &--gap {
        margin-bottom: 12px;
      }

      &--gap-xl {
        margin-bottom: 36px;

        @include media-breakpoint-up(md) {
          margin-bottom: 185px;
        }
      }

      &--gap-l {
        margin-bottom: 27px;

        @include media-breakpoint-up(md) {
          margin-bottom: 70px;
        }
      }

      &--gap-m {
        margin-bottom: 35px;
      }

      &--gap-ml {
        margin-bottom: 55px;

        @include media-breakpoint-up(md) {
          margin-bottom: 58px;
        }
      }

      &--border {
        max-width: 280px;
        height: 2px;
      }

      &--subscription {
        max-width: 50px;
        height: 8px;
        border-radius: 4px;
      }

      &--price {
        max-width: 150px;
        height: 8px;
        border-radius: 4px;
      }

      &--button {
        max-width: 200px;
        border-radius: 2px;
        height: 36px;

        @include media-breakpoint-up(md) {
          max-width: 300px;
        }
      }

      &--tabs {
        max-width: 150px;
        border-radius: 4px;
        height: 8px;
        margin-bottom: 23px;

        @include media-breakpoint-up(md) {
          max-width: 240px;
          border-radius: 7.5px;
          height: 12px;
          margin-bottom: 58px;
        }
      }

      &--title {
        max-width: 280px;
        border-radius: 7.5px;
        height: 12px;
        margin-bottom: 31px;

        @include media-breakpoint-up(md) {
          max-width: 240px;
          margin-bottom: 21px;
        }
      }

      &--summary {
        max-width: 325px;
        border-radius: 4px;
        height: 8px;

        @include media-breakpoint-up(md) {
          max-width: 520px;
        }
      }
    }
  }
</style>
