<template>
  <md-checkbox
    @change="isChanged" :disabled="disabled"
    class="tag text-center m-0 st_color_main_lighter st_none-light-small"
    v-model="selected" :value="value">
    {{ name }}
  </md-checkbox>
</template>

<script>
  export default {
    model: {
      prop:  'checked',
      event: 'change',
    },
    props: {
      name: {
        type:     String,
        required: true,
      },
      value: {
        type:     String,
        required: true,
      },
      disabled: {
        type:    Boolean,
        default: false,
      },
      checked: {
        type: [String, Array],
        default () {
          return []
        },
      },
    },
    computed: {
      selected: {
        get () {
          return this.checked
        },
        set (val) {
          this.$emit('change', val)
        },
      },
    },
    methods: {
      isChanged (newVal) {
        if (Array.isArray(newVal)) {
          if (newVal.includes(this.value)) {
            this.$emit('selected', this.value)
          } else {
            this.$emit('removed', this.value)
          }
        } else if (newVal === this.value) {
          this.$emit('selected', this.value)
        } else {
          this.$emit('removed', this.value)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tag {
    user-select: none;

    /deep/ {
      .md-checkbox-container {
        display: none;
      }

      .md-checkbox-label {
        min-width: 110px;
        padding: 5px 20px;
        margin: 0;
        border: 1px solid rgba($st_color_main_lightest, 0.2);
        border-radius: 16px;
        height: auto;
        transition: .5s background-color, .3s color;
        background-color: $white;

        &:hover {
          background-color: rgba($st_color_main_lightest, 0.2);
        }
      }
    }

    &.md-checked {
      /deep/ {
        .md-checkbox-label {
          background-color: $st_color_main_lightest;
          color: $white;
        }
      }
    }
  }
</style>
